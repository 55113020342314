import React, { useState, useEffect } from "react"
import { AiOutlineSearch } from "react-icons/ai"
import { input } from "src/i18next-parser.config"

type Props = {
  title: string
  searchDefaultTxt: string
  value?: string
  setFilterSearch: (value?: string) => void
}

export const FilterSearch: React.FC<Props> = ({
  title,
  searchDefaultTxt,
  value,
  setFilterSearch
}: Props) => {
  const [query, setQuery] = useState(value)
  const [searchErr, setSearchErr] = useState<string | null>(null)

  useEffect(() => {
    const timeOutId = setTimeout(function () {
      if (query?.length == 0) {
        setFilterSearch("")
      } else {
        setFilterSearch(query)
      }
      // setSearchErr("Enter valid ID")
      setTimeout(() => {
        setSearchErr(null)
      }, 2000)
      /*
      if (query?.length == 36) {
        setFilterSearch(query)
      }
      if ((query && query?.length > 3) || (query && query?.length < 3)) {
        setSearchErr("Enter valid ID")
        setTimeout(() => {
          setSearchErr(null)
        }, 2000)
      }*/
    }, 500)
    return () => clearTimeout(timeOutId)
  }, [query])

  const handleChange = (searchValue?: any) => {
    setQuery(searchValue.value)
  }

  return (
    <>
      <div className="search-addon right-addon">
        <span>{title} : </span>
        <div className="search-crypto-input">
          <input
            type="search"
            value={query || ""}
            placeholder={searchDefaultTxt}
            onChange={(e: React.ChangeEvent<Element>) => handleChange(e.target)}
          />
          {value === "" || undefined ? (
            <AiOutlineSearch
              className="search-icon"
              onClick={(e: React.MouseEvent<Element>) => {
                e.stopPropagation()
              }}
            />
          ) : null}
        </div>
        {searchErr ? (
          <span style={{ color: "red", marginLeft: "10px" }}>{searchErr}</span>
        ) : null}
      </div>
    </>
  )
}
