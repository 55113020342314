// Country codes available in WadzPay API
const wadzPayCountryCodes = [
  "AC",
  "AD",
  "AE",
  "AF",
  "AG",
  "AI",
  "AL",
  "AM",
  "AN",
  "AO",
  "AQ",
  "AR",
  "AS",
  "AT",
  "AU",
  "AW",
  "AX",
  "AZ",
  "BA",
  "BB",
  "BD",
  "BE",
  "BF",
  "BG",
  "BH",
  "BI",
  "BJ",
  "BL",
  "BM",
  "BN",
  "BO",
  "BQ",
  "BR",
  "BS",
  "BT",
  "BU",
  "BV",
  "BW",
  "BY",
  "BZ",
  "CA",
  "CC",
  "CD",
  "CF",
  "CG",
  "CH",
  "CI",
  "CK",
  "CL",
  "CM",
  "CN",
  "CO",
  "CP",
  "CR",
  "CS",
  "CU",
  "CV",
  "CW",
  "CX",
  "CY",
  "CZ",
  "DE",
  "DG",
  "DJ",
  "DK",
  "DM",
  "DO",
  "DZ",
  "EA",
  "EC",
  "EE",
  "EG",
  "EH",
  "ER",
  "ES",
  "ET",
  "EU",
  "EZ",
  "FI",
  "FJ",
  "FK",
  "FM",
  "FO",
  "FR",
  "FX",
  "GA",
  "GB",
  "GD",
  "GE",
  "GF",
  "GG",
  "GH",
  "GI",
  "GL",
  "GM",
  "GN",
  "GP",
  "GQ",
  "GR",
  "GS",
  "GT",
  "GU",
  "GW",
  "GY",
  "HK",
  "HM",
  "HN",
  "HR",
  "HT",
  "HU",
  "IC",
  "ID",
  "IE",
  "IL",
  "IM",
  "IN",
  "IO",
  "IQ",
  "IR",
  "IS",
  "IT",
  "JE",
  "JM",
  "JO",
  "JP",
  "KE",
  "KG",
  "KH",
  "KI",
  "KM",
  "KN",
  "KP",
  "KR",
  "KW",
  "KY",
  "KZ",
  "LA",
  "LB",
  "LC",
  "LI",
  "LK",
  "LR",
  "LS",
  "LT",
  "LU",
  "LV",
  "LY",
  "MA",
  "MC",
  "MD",
  "ME",
  "MF",
  "MG",
  "MH",
  "MK",
  "ML",
  "MM",
  "MN",
  "MO",
  "MP",
  "MQ",
  "MR",
  "MS",
  "MT",
  "MU",
  "MV",
  "MW",
  "MX",
  "MY",
  "MZ",
  "NA",
  "NC",
  "NE",
  "NF",
  "NG",
  "NI",
  "NL",
  "NO",
  "NP",
  "NR",
  "NT",
  "NU",
  "NZ",
  "OM",
  "PA",
  "PE",
  "PF",
  "PG",
  "PH",
  "PK",
  "PL",
  "PM",
  "PN",
  "PR",
  "PS",
  "PT",
  "PW",
  "PY",
  "QA",
  "RE",
  "RO",
  "RS",
  "RU",
  "RW",
  "SA",
  "SB",
  "SC",
  "SD",
  "SE",
  "SF",
  "SG",
  "SH",
  "SI",
  "SJ",
  "SK",
  "SL",
  "SM",
  "SN",
  "SO",
  "SR",
  "SS",
  "ST",
  "SU",
  "SV",
  "SX",
  "SY",
  "SZ",
  "TA",
  "TC",
  "TD",
  "TF",
  "TG",
  "TH",
  "TJ",
  "TK",
  "TL",
  "TM",
  "TN",
  "TO",
  "TP",
  "TR",
  "TT",
  "TV",
  "TW",
  "TZ",
  "UA",
  "UG",
  "UK",
  "UM",
  "US",
  "UY",
  "UZ",
  "VA",
  "VC",
  "VE",
  "VG",
  "VI",
  "VN",
  "VU",
  "WF",
  "WS",
  "XK",
  "YE",
  "YT",
  "YU",
  "ZA",
  "ZM",
  "ZR",
  "ZW"
]

// https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
enum IsoCountryCode {
  AD = "Andorra",
  AF = "Afghanistan",
  AG = "Antigua and Barbuda",
  AI = "Anguilla",
  AL = "Albania",
  AM = "Armenia",
  AO = "Angola",
  AQ = "Antarctica",
  AR = "Argentina",
  AS = "American Samoa",
  AT = "Austria",
  AU = "Australia",
  AW = "Aruba",
  AX = "Åland Islands",
  AZ = "Azerbaijan",
  BA = "Bosnia and Herzegovina",
  BB = "Barbados",
  BD = "Bangladesh",
  BE = "Belgium",
  BF = "Burkina Faso",
  BG = "Bulgaria",
  BH = "Bahrain",
  BI = "Burundi",
  BJ = "Benin",
  BL = "Saint Barthélemy",
  BM = "Bermuda",
  BN = "Brunei Darussalam",
  BO = "Bolivia (Plurinational State of)",
  BQ = "Bonaire, Sint Eustatius and Saba",
  BR = "Brazil",
  BS = "Bahamas",
  BT = "Bhutan",
  BV = "Bouvet Island",
  BW = "Botswana",
  BY = "Belarus",
  BZ = "Belize",
  CA = "Canada",
  CC = "Cocos (Keeling) Islands",
  CD = "Congo, Democratic Republic of the",
  CF = "Central African Republic",
  CG = "Congo",
  CH = "Switzerland",
  CI = "Côte d'Ivoire",
  CK = "Cook Islands",
  CL = "Chile",
  CM = "Cameroon",
  CN = "China",
  CO = "Colombia",
  CR = "Costa Rica",
  CU = "Cuba",
  CV = "Cabo Verde",
  CW = "Curaçao",
  CX = "Christmas Island",
  CY = "Cyprus",
  CZ = "Czechia",
  DE = "Germany",
  DJ = "Djibouti",
  DK = "Denmark",
  DM = "Dominica",
  DO = "Dominican Republic",
  DZ = "Algeria",
  EC = "Ecuador",
  EE = "Estonia",
  EG = "Egypt",
  EH = "Western Sahara",
  ER = "Eritrea",
  ES = "Spain",
  ET = "Ethiopia",
  FI = "Finland",
  FJ = "Fiji",
  FK = "Falkland Islands (Malvinas)",
  FM = "Micronesia (Federated States of)",
  FO = "Faroe Islands",
  FR = "France",
  GA = "Gabon",
  GB = "United Kingdom of Great Britain and Northern Ireland",
  GD = "Grenada",
  GE = "Georgia",
  GF = "French Guiana",
  GG = "Guernsey",
  GH = "Ghana",
  GI = "Gibraltar",
  GL = "Greenland",
  GM = "Gambia",
  GN = "Guinea",
  GP = "Guadeloupe",
  GQ = "Equatorial Guinea",
  GR = "Greece",
  GS = "South Georgia and the South Sandwich Islands",
  GT = "Guatemala",
  GU = "Guam",
  GW = "Guinea-Bissau",
  GY = "Guyana",
  HK = "Hong Kong",
  HM = "Heard Island and McDonald Islands",
  HN = "Honduras",
  HR = "Croatia",
  HT = "Haiti",
  HU = "Hungary",
  ID = "Indonesia",
  IE = "Ireland",
  IL = "Israel",
  IM = "Isle of Man",
  IN = "India",
  IO = "British Indian Ocean Territory",
  IQ = "Iraq",
  IR = "Iran (Islamic Republic of)",
  IS = "Iceland",
  IT = "Italy",
  JE = "Jersey",
  JM = "Jamaica",
  JO = "Jordan",
  JP = "Japan",
  KE = "Kenya",
  KG = "Kyrgyzstan",
  KH = "Cambodia",
  KI = "Kiribati",
  KM = "Comoros",
  KN = "Saint Kitts and Nevis",
  KP = "Korea (Democratic People's Republic of)",
  KR = "Korea, Republic of",
  KW = "Kuwait",
  KY = "Cayman Islands",
  KZ = "Kazakhstan",
  LA = "Lao People's Democratic Republic",
  LB = "Lebanon",
  LC = "Saint Lucia",
  LI = "Liechtenstein",
  LK = "Sri Lanka",
  LR = "Liberia",
  LS = "Lesotho",
  LT = "Lithuania",
  LU = "Luxembourg",
  LV = "Latvia",
  LY = "Libya",
  MA = "Morocco",
  MC = "Monaco",
  MD = "Moldova, Republic of",
  ME = "Montenegro",
  MF = "Saint Martin (French part)",
  MG = "Madagascar",
  MH = "Marshall Islands",
  MK = "North Macedonia",
  ML = "Mali",
  MM = "Myanmar",
  MN = "Mongolia",
  MO = "Macao",
  MP = "Northern Mariana Islands",
  MQ = "Martinique",
  MR = "Mauritania",
  MS = "Montserrat",
  MT = "Malta",
  MU = "Mauritius",
  MV = "Maldives",
  MW = "Malawi",
  MX = "Mexico",
  MY = "Malaysia",
  MZ = "Mozambique",
  NA = "Namibia",
  NC = "New Caledonia",
  NE = "Niger",
  NF = "Norfolk Island",
  NG = "Nigeria",
  NI = "Nicaragua",
  NL = "Netherlands",
  NO = "Norway",
  NP = "Nepal",
  NR = "Nauru",
  NU = "Niue",
  NZ = "New Zealand",
  OM = "Oman",
  PA = "Panama",
  PE = "Peru",
  PF = "French Polynesia",
  PG = "Papua New Guinea",
  PH = "Philippines",
  PK = "Pakistan",
  PL = "Poland",
  PM = "Saint Pierre and Miquelon",
  PN = "Pitcairn",
  PR = "Puerto Rico",
  PS = "Palestine, State of",
  PT = "Portugal",
  PW = "Palau",
  PY = "Paraguay",
  QA = "Qatar",
  RE = "Réunion",
  RO = "Romania",
  RS = "Serbia",
  RU = "Russian Federation",
  RW = "Rwanda",
  SA = "Saudi Arabia",
  SB = "Solomon Islands",
  SC = "Seychelles",
  SD = "Sudan",
  SE = "Sweden",
  SG = "Singapore",
  SH = "Saint Helena, Ascension and Tristan da Cunha",
  SI = "Slovenia",
  SJ = "Svalbard and Jan Mayen",
  SK = "Slovakia",
  SL = "Sierra Leone",
  SM = "San Marino",
  SN = "Senegal",
  SO = "Somalia",
  SR = "Suriname",
  SS = "South Sudan",
  ST = "Sao Tome and Principe",
  SV = "El Salvador",
  SX = "Sint Maarten (Dutch part)",
  SY = "Syrian Arab Republic",
  SZ = "Eswatini",
  TC = "Turks and Caicos Islands",
  TD = "Chad",
  TF = "French Southern Territories",
  TG = "Togo",
  TH = "Thailand",
  TJ = "Tajikistan",
  TK = "Tokelau",
  TL = "Timor-Leste",
  TM = "Turkmenistan",
  TN = "Tunisia",
  TO = "Tonga",
  TR = "Turkey",
  TT = "Trinidad and Tobago",
  TV = "Tuvalu",
  TW = "Taiwan, Province of China",
  TZ = "Tanzania, United Republic of",
  UA = "Ukraine",
  UG = "Uganda",
  UM = "United States Minor Outlying Islands",
  US = "United States of America",
  UY = "Uruguay",
  UZ = "Uzbekistan",
  VA = "Holy See",
  VC = "Saint Vincent and the Grenadines",
  VE = "Venezuela (Bolivarian Republic of)",
  VG = "Virgin Islands (British)",
  VI = "Virgin Islands (U.S.)",
  VN = "Viet Nam",
  VU = "Vanuatu",
  WF = "Wallis and Futuna",
  WS = "Samoa",
  YE = "Yemen",
  YT = "Mayotte",
  ZA = "South Africa",
  ZM = "Zambia",
  ZW = "Zimbabwe"
}

// Wadzpay country codes that have a valid ISO 3166-1 alpha-2 code
export const countryCodes = wadzPayCountryCodes.reduce((prev, curr) => {
  const displayName = Object.entries(IsoCountryCode).find(
    (x) => x[0] === curr
  )?.[1]
  if (displayName) {
    return { ...prev, ...{ [curr]: displayName } }
  }
  return prev
}, {} as { [key: string]: string })

// Industry types available in WadzPay API
const wadzPayIndustryTypes = [
  "ACCOUNTING",
  "AIRLINES_AVIATION",
  "ALTERNATIVE_DISPUTE_RESOLUTION",
  "ALTERNATIVE_MEDICINE",
  "ANIMATION",
  "APPAREL_FASHION",
  "ARCHITECTURE_PLANNING",
  "ARTS_CRAFTS",
  "AUTOMOTIVE",
  "AVIATION_AEROSPACE",
  "BANKING_MORTGAGE",
  "BIOTECHNOLOGY_GREENTECH",
  "BROADCAST_MEDIA",
  "BUILDING_MATERIALS",
  "BUSINESS_SUPPLIES_EQUIPMENT",
  "CAPITAL_MARKETS_HEDGE_FUND_PRIVATE_EQUITY",
  "CHEMICALS",
  "CIVIC_SOCIAL_ORGANIZATION",
  "CIVIL_ENGINEERING",
  "COMMERCIAL_REAL_ESTATE",
  "COMPUTER_GAMES",
  "COMPUTER_HARDWARE",
  "COMPUTER_NETWORKING",
  "COMPUTER_SOFTWARE_ENGINEERING",
  "COMPUTER_NETWORK_SECURITY",
  "CONSTRUCTION",
  "CONSUMER_ELECTRONICS",
  "CONSUMER_GOODS",
  "CONSUMER_SERVICES",
  "COSMETICS",
  "DAIRY",
  "DEFENSE_SPACE",
  "DESIGN",
  "E_LEARNING",
  "EDUCATION_MANAGEMENT",
  "ELECTRICAL_ELECTRONIC_MANUFACTURING",
  "ENTERTAINMENT_MOVIE_PRODUCTION",
  "ENVIRONMENTAL_SERVICES",
  "EVENTS_SERVICES",
  "EXECUTIVE_OFFICE",
  "FACILITIES_SERVICES",
  "FARMING",
  "FINANCIAL_SERVICES",
  "FINE_ART",
  "FISHERY",
  "FOOD_PRODUCTION",
  "FOOD_BEVERAGES",
  "FUNDRAISING",
  "FURNITURE",
  "GAMBLING_CASINOS",
  "GLASS_CERAMICS_CONCRETE",
  "GOVERNMENT_ADMINISTRATION",
  "GOVERNMENT_RELATIONS",
  "GRAPHIC_DESIGN_WEB_DESIGN",
  "HEALTH_FITNESS",
  "HIGHER_EDUCATION_ACADAMIA",
  "HOSPITAL_HEALTH_CARE",
  "HOSPITALITY",
  "HUMAN_RESOURCES_HR",
  "IMPORT_EXPORT",
  "INDIVIDUAL_FAMILY_SERVICES",
  "INDUSTRIAL_AUTOMATION",
  "INFORMATION_SERVICES",
  "INFORMATION_TECHNOLOGY_IT",
  "INSURANCE",
  "INTERNATIONAL_AFFAIRS",
  "INTERNATIONAL_TRADE_DEVELOPMENT",
  "INTERNET",
  "INVESTMENT_BANKING_VENTURE",
  "INVESTMENT_MANAGEMENT_HEDGE_FUND_PRIVATE_EQUITY",
  "JUDICIARY",
  "LAW_ENFORCEMENT",
  "LAW_PRACTICE_LAW_FIRMS",
  "LEGAL_SERVICES",
  "LEGISLATIVE_OFFICE",
  "LEISURE_TRAVEL",
  "LIBRARY",
  "LOGISTICS_PROCUREMENT",
  "LUXURY_GOODS_JEWELRY",
  "MACHINERY",
  "MANAGEMENT_CONSULTING",
  "MARITIME",
  "MARKET_RESEARCH",
  "MARKETING_ADVERTISING_SALES",
  "MECHANICAL_OR_INDUSTRIAL_ENGINEERING",
  "MEDIA_PRODUCTION",
  "MEDICAL_EQUIPMENT",
  "MEDICAL_PRACTICE",
  "MENTAL_HEALTH_CARE",
  "MILITARY_INDUSTRY",
  "MINING_METALS",
  "MOTION_PICTURES_FILM",
  "MUSEUMS_INSTITUTIONS",
  "MUSIC",
  "NANOTECHNOLOGY",
  "NEWSPAPERS_JOURNALISM",
  "NON_PROFIT_VOLUNTEERING",
  "OIL_ENERGY_SOLAR_GREENTECH",
  "ONLINE_PUBLISHING",
  "OTHER_INDUSTRY",
  "OUTSOURCING_OFFSHORING",
  "PACKAGE_FREIGHT_DELIVERY",
  "PACKAGING_CONTAINERS",
  "PAPER_FOREST_PRODUCTS",
  "PERFORMING_ARTS",
  "PHARMACEUTICALS",
  "PHILANTHROPY",
  "PHOTOGRAPHY",
  "PLASTICS",
  "POLITICAL_ORGANIZATION",
  "PRIMARY_SECONDARY_EDUCATION",
  "PRINTING",
  "PROFESSIONAL_TRAINING",
  "PROGRAM_DEVELOPMENT",
  "PUBLIC_RELATIONS_PR",
  "PUBLIC_SAFETY",
  "PUBLISHING_INDUSTRY",
  "RAILROAD_MANUFACTURE",
  "RANCHING",
  "REAL_ESTATE_MORTGAGE",
  "RECREATIONAL_FACILITIES_SERVICES",
  "RELIGIOUS_INSTITUTIONS",
  "RENEWABLES_ENVIRONMENT",
  "RESEARCH_INDUSTRY",
  "RESTAURANTS",
  "RETAIL_INDUSTRY",
  "SECURITY_INVESTIGATIONS",
  "SEMICONDUCTORS",
  "SHIPBUILDING",
  "SPORTING_GOODS",
  "SPORTS",
  "STAFFING_RECRUITING",
  "SUPERMARKETS",
  "TELECOMMUNICATIONS",
  "TEXTILES",
  "THINK_TANKS",
  "TOBACCO",
  "TRANSLATION_LOCALIZATION",
  "TRANSPORTATION",
  "UTILITIES",
  "VENTURE_CAPITAL_VC",
  "VETERINARY",
  "WAREHOUSING",
  "WHOLESALE",
  "WINE_SPIRITS",
  "WIRELESS",
  "WRITING_EDITING"
]

// Titalise industry types
export const industryTypes = wadzPayIndustryTypes.reduce((prev, curr) => {
  const parts = curr.split("_")
  const words = parts
    .map((word, i) => {
      if (word.length === 2) {
        if (i === parts.length - 1) {
          return word // 2 letter word at end of sentence are probably a acronym
        } else {
          return word.toLowerCase() // other 2 letter words are probably a conjunction
        }
      } else {
        return word.charAt(0) + word.slice(1).toLowerCase()
      }
    })
    .join(" ")

  return { ...prev, [curr]: words }
}, {} as { [key: string]: string })
