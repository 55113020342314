import React, { useState, useEffect, useContext } from "react"
import Modal from "react-bootstrap/Modal"
import Container from "react-bootstrap/Container"
import { useMerchantDetails } from "src/api/user"
import { MerchantContext } from "src/context/Merchant"

function TermsAndConditionsPopupModal(props: any) {
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(true)
  const handleClose = () => setShowTermsAndConditions(false)
  const { merchantDetails } = useContext(MerchantContext)
  const [termsAndCondtitonPara1, setTermsAndConditionPara1] = useState()
  const [termsAndCondtitonPara2, setTermsAndConditionPara2] = useState()
  const [termsAndCondtitonPara3, setTermsAndConditionPara3] = useState()
  const [termsAndCondtitonPara4, setTermsAndConditionPara4] = useState()

  useEffect(() => {
    if (merchantDetails?.merchant && merchantDetails.merchant.tnc) {
      const termsJson = JSON.parse(merchantDetails.merchant.tnc)
      setTermsAndConditionPara1(termsJson["para1"])
      setTermsAndConditionPara2(termsJson["para2"])
      setTermsAndConditionPara3(termsJson["para3"])
      setTermsAndConditionPara4(termsJson["para4"])
    }
  }, [merchantDetails])

  return (
    <>
      <Modal
        show={showTermsAndConditions}
        onHide={handleClose}
        size="lg"
        centered
        backdrop="static"
      >
        <Modal.Header style={{ justifyContent: "center" }}>
          <h2 className="refund-header" style={{ justifyContent: "center" }}>
            Terms and Conditions
          </h2>
        </Modal.Header>
        <Modal.Body
          style={{ height: "400px" }}
          className="show-grid trxbalances"
        >
          <Container className="textareaTnc">
            {merchantDetails?.merchant?.tnc && (
              <ol>
                <li key={1}>{termsAndCondtitonPara1}</li>
                <li key={2}>{termsAndCondtitonPara2}</li>
                <li key={3}>{termsAndCondtitonPara3}</li>
                <li key={4}>{termsAndCondtitonPara4}</li>
              </ol>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <div style={{ textAlign: "center" }}>
            <button
              className="wdz-btn-grey wdz-btn-md ml-4"
              onClick={() => props.showhide()}
            >
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default TermsAndConditionsPopupModal
