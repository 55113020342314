import React, { PropsWithChildren, useEffect, useState } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { MerchantDetailsData } from "src/api/models"
import { useMerchantDetails } from "src/api/user"
import { RouteType } from "src/constants/routeTypes"
import SignedInUser from "src/auth/SignedInUser"
import SignedOutUser from "src/auth/SignedOutUser"
type MerchantContextType = {
  merchantDetails?: MerchantDetailsData
  isFetching: boolean
}

export const MerchantContext = React.createContext<MerchantContextType>({
  merchantDetails: undefined,
  isFetching: true
})

type Props = PropsWithChildren<{}>

export const SignedInMerchantContextProvider: React.FC<Props> = ({
  children
}: Props) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { data, isFetching, error, refetch } = useMerchantDetails()
  const [merchantDetailsData, setMerchantData] = useState<MerchantDetailsData>()

  useEffect(() => {
    if (data) {
      setMerchantData(data)
    }
  }, [data])

  useEffect(() => {
    if (!data) {
      refetch()
    }
  }, [pathname])

  const getAction = () => {
    // Display a blank screen while fetching the merchant details
    if (isFetching) {
      return <></>
    }

    // Display the error message if not MERCHANT_NOT_FOUND
    if (error && error.message !== "ERROR_MESSAGE.MERCHANT_NOT_FOUND") {
      return (
        <div className="alert alert-danger" role="alert">
          <div>{error.message}</div>
          <div>{t("Unable to retrieve merchant details from the server")}</div>
        </div>
      )
    }

    // Send to home page if merchant details exist and requesting the merchant details page
    if (merchantDetailsData && pathname === RouteType.MERCHANT_DETAILS) {
      return <Navigate to={RouteType.HOME} />
    }

    // Send to merchant details page if merchant is not found
    if (
      pathname !== RouteType.MERCHANT_DETAILS &&
      error &&
      error.message === "ERROR_MESSAGE.MERCHANT_NOT_FOUND"
    ) {
      return <Navigate to={RouteType.MERCHANT_DETAILS} />
    }

    // Otherwise, display the content
    return (
      <MerchantContext.Provider
        value={{ merchantDetails: merchantDetailsData, isFetching }}
      >
        {children}
      </MerchantContext.Provider>
    )
  }

  return getAction()
}

/**
 * This will load the merchant data if the current user is logged in
 * otherwise it just renders the children
 */
export const MerchantContextProvider: React.FC<Props> = ({
  children
}: Props) => {
  return (
    <>
      <SignedInUser>
        <SignedInMerchantContextProvider>
          {children}
        </SignedInMerchantContextProvider>
      </SignedInUser>
      <SignedOutUser>{children}</SignedOutUser>
    </>
  )
}
