import React, { useEffect, useState } from "react"
import "./RefundFormStyle.scss"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import { useSubmitRefundFormData } from "src/api/user"
import Modal from "react-bootstrap/Modal"
import { useGetExchangeRateForRefund } from "src/api/onRamp"
import TermsAndConditionsPopupModal from "src/components/ui/PopUps/TermsAndConditionsPopupModal"

import SavedGIF from "./success.gif"
import env, { ENV } from "../../env"

export default function RefundForm({ data, id }: any): JSX.Element {
  const [checkVal, setCheckVal] = useState(false)
  const [formErrorMessages, setFormErrorMessages] = useState({
    refundUserNameError: "",
    refundWalletAddress: "",
    refundReasonError: "",
    checkBoxError: "",
    confirmWalletAddress: ""
  })
  const digitalAsset =
    env.TYPE == ENV.DEV || env.TYPE == ENV.TESTING ? "BTC" : "USDT"
  const { data: refundExchangeRates } = useGetExchangeRateForRefund(
    data?.fiatAsset
  )

  const [show, setShow] = useState(false)
  const { mutate: submitRefundForm } = useSubmitRefundFormData()
  const [refundVal, setRefundVal] = useState({
    refundUserName: data.refundUserName ? data.refundUserName : "",
    refundUserMobile: data.refundUserMobile,
    refundUserEmail: data.refundUserEmail,
    refundWalletAddress: data.refundWalletAddress
      ? data.refundWalletAddress
      : data.sourceWalletAddress,
    reasonForRefund: data.refundReason,
    reconfirmWalletAddress: data.sourceWalletAddress
  })
  const [storeRefundFiatAmount, setStoreRefundFiatAmount] = useState(0)
  const [successMsg, setSuccessMsg] = useState("")
  const handleChange = (e: any) => {
    const { name, value } = e.target
    setRefundVal({ ...refundVal, [name]: value })
  }
  const [showTermsAndConditionsForm, setShowTermsAndConditionsForm] =
    useState(false)

  useEffect(() => {
    if (refundExchangeRates && data) {
      renderRefundAmount()
    }
  }, [refundExchangeRates, data])

  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => setShow(true)
  const submitData = (values: any) => {
    values.preventDefault()
    const isReasonForRefundValid =
      refundVal.reasonForRefund && refundVal.reasonForRefund.length > 0
    const isRefundUserNameValid =
      refundVal.refundUserName &&
      refundVal.refundUserName.length > 0 &&
      refundVal.refundUserName.match(/^[A-Za-z ]+$/)
    const isRefundWalletAddressValid =
      refundVal.reconfirmWalletAddress &&
      refundVal.reconfirmWalletAddress.length > 0 &&
      refundVal.refundWalletAddress.length == 42 &&
      /^0x[0-9a-fA-F]{40}$/.test(refundVal.refundWalletAddress)
    const isConfirmWalletAddressValid =
      refundVal.refundWalletAddress === refundVal.reconfirmWalletAddress
    const isCheckError = checkVal ? true : false

    // const obj = {
    //   transactionId: data?.uuid,
    //   refundUserName: refundVal.refundUserName,
    //   refundUserMobile: refundVal.refundUserMobile
    //     ? refundVal.refundUserMobile
    //     : null,
    //   refundUserEmail: refundVal.refundUserEmail
    //     ? refundVal.refundUserEmail
    //     : null,
    //   refundWalletAddress: refundVal.refundWalletAddress,
    //   refundDigitalType: data?.refundDigitalCurrencyType,
    //   refundFiatType: data?.refundFiatType,
    //   refundAmountDigital: storeRefundFiatAmount,
    //   reasonForRefund: refundVal.reasonForRefund,
    //   refundAmountFiat: data?.refundFiatAmount,
    //   refundMode: data?.refundMode
    // }
    // console.log(obj)
    if (
      isReasonForRefundValid &&
      isRefundUserNameValid &&
      isRefundWalletAddressValid &&
      isCheckError &&
      isConfirmWalletAddressValid
    ) {
      submitRefundForm({
        transactionId: data?.uuid,
        refundUserName: refundVal.refundUserName,
        refundUserMobile: refundVal.refundUserMobile
          ? refundVal.refundUserMobile
          : null,
        refundUserEmail: refundVal.refundUserEmail
          ? refundVal.refundUserEmail
          : null,
        refundWalletAddress: refundVal.refundWalletAddress,
        refundDigitalType: data?.refundDigitalCurrencyType,
        refundFiatType: data?.refundFiatType,
        refundAmountDigital: storeRefundFiatAmount,
        reasonForRefund: refundVal.reasonForRefund,
        refundAmountFiat: data?.refundFiatAmount,
        refundMode: data?.refundMode
      })
      setSuccessMsg("Data Submitted SuccessFully!!")
      setFormErrorMessages({
        refundUserNameError: "",
        refundWalletAddress: "",
        refundReasonError: "",
        checkBoxError: "",
        confirmWalletAddress: ""
      })
      setShow(false)
    } else {
      setShow(true)
      setFormErrorMessages((prevState) => ({
        ...prevState,
        refundReasonError: !isReasonForRefundValid
          ? "Reason For Refund Field is empty"
          : "",
        refundUserNameError: !isRefundUserNameValid
          ? "Username Field is Invalid"
          : "",
        refundWalletAddress: !isRefundWalletAddressValid
          ? "Wallet Address Field is Invalid"
          : "",
        checkBoxError:
          isCheckError === false
            ? "Accept the Terms and Conditions Checkbox"
            : "",
        confirmWalletAddress: !isConfirmWalletAddressValid
          ? "Wallet address and confirm wallet address must be same"
          : ""
      }))
      setSuccessMsg("")
    }
  }

  const onCancelClick = () => {
    setRefundVal({
      refundUserName: data.refundUserName ? data.refundUserName : "",
      refundUserMobile: data.refundUserMobile,
      refundUserEmail: data.refundUserEmail,
      refundWalletAddress: data.refundWalletAddress,
      reasonForRefund: data.refundReason,
      reconfirmWalletAddress: ""
    })
    setFormErrorMessages({
      refundUserNameError: "",
      refundWalletAddress: "",
      refundReasonError: "",
      checkBoxError: "",
      confirmWalletAddress: ""
    })
    setCheckVal(false)
    setSuccessMsg("")
  }

  function renderRefundAmount() {
    if (refundExchangeRates) {
      const value = refundExchangeRates[digitalAsset] * data.refundFiatAmount
      const finalVal = value.toFixed(8)
      setStoreRefundFiatAmount(parseFloat(finalVal))
    }
  }

  return (
    <div className="refund-main">
      <h2 className="refund-header">Customers Verification form for Refund</h2>
      <Modal
        show={show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{"Required Fields"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {formErrorMessages.checkBoxError && (
              <li>{formErrorMessages.checkBoxError}</li>
            )}
            {formErrorMessages.refundReasonError && (
              <li>{formErrorMessages.refundReasonError}</li>
            )}
            {formErrorMessages.refundUserNameError && (
              <li>{formErrorMessages.refundUserNameError}</li>
            )}
            {formErrorMessages.refundWalletAddress && (
              <li>{formErrorMessages.refundWalletAddress}</li>
            )}
            {formErrorMessages.confirmWalletAddress && (
              <li>{formErrorMessages.confirmWalletAddress}</li>
            )}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {successMsg ? (
        <div style={{ textAlign: "center" }}>
          <div className="save-bg-img">
            <img className="save-img" src={SavedGIF} alt="Save Image" />
          </div>
          <div
            style={{ fontSize: "30px", fontWeight: 600, paddingTop: "20px" }}
          >
            {successMsg}
          </div>
        </div>
      ) : (
        <Form onSubmit={submitData}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Transaction reference</Form.Label>
            <Form.Control
              type="text"
              placeholder="Transaction reference"
              value={data?.uuid}
              name="transactionId"
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Customer Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Customer Name"
              onChange={handleChange}
              value={refundVal.refundUserName}
              name="refundUserName"
              disabled={data?.refundUserName ? true : false}
            />
            {/* {refundVal.refundUserName &&
              !refundVal.refundUserName.match(/^[A-Za-z]+$/) && (
                <p className="text-danger">Enter Valid Name!</p>
              )} */}
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Mobile number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Mobile number"
              onChange={handleChange}
              value={refundVal.refundUserMobile}
              name="refundUserMobile"
              disabled={
                data?.refundUserMobile
                  ? true
                  : false || data?.refundUserEmail.length > 0
                  ? true
                  : false
              }
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email Address"
              onChange={handleChange}
              value={refundVal.refundUserEmail}
              name="refundUserEmail"
              disabled={
                data?.refundUserEmail
                  ? true
                  : false || data?.refundUserMobile.length > 0
                  ? true
                  : false
              }
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Digital currency amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Digital currency amount"
              value={data?.totalDigitalCurrencyReceived}
              name="refundAmountDigital"
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Digital currency name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digital currency name"
              value={data?.refundDigitalCurrencyType}
              name="refundDigitalType"
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Refund Amount in {data?.fiatAsset}</Form.Label>
            <Form.Control
              type="number"
              placeholder={`Refund Amount in ${data?.fiatAsset}`}
              value={data?.refundFiatAmount}
              name="refundAmountFiat"
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Refund Amount in {digitalAsset}</Form.Label>
            <Form.Control
              type="number"
              placeholder={`Refund Amount in ${digitalAsset}`}
              value={storeRefundFiatAmount}
              name="refundFiatAmount"
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Reason for Refund</Form.Label>
            <Form.Control
              type="text"
              placeholder="Reason for Refund"
              onChange={handleChange}
              value={refundVal.reasonForRefund || ""}
              name="reasonForRefund"
              disabled={data?.refundReason ? true : false}
            />
            <Form.Text className="text-muted">
              to be filled by customer
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Source Wallet Address</Form.Label>
            <Form.Control
              type="text"
              placeholder={`Source Wallet Address`}
              value={data?.sourceWalletAddress}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>{digitalAsset} wallet address</Form.Label>
            <Form.Control
              type="text"
              maxLength={42}
              placeholder={`${digitalAsset} wallet address`}
              onChange={handleChange}
              value={refundVal.refundWalletAddress}
              name="refundWalletAddress"
              disabled={data?.refundWalletAddress ? true : false}
            />
            <Form.Text className="text-muted">
              to be filled by customer
            </Form.Text>
            {/* {refundVal.refundWalletAddress === "" &&
              (refundVal.refundWalletAddress.length < 24 ||
                refundVal.refundWalletAddress.length > 32) && (
                <p className="text-danger">Enter Valid Wallet Address!</p>
              )} */}
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Reconfirm {digitalAsset} wallet address</Form.Label>
            <Form.Control
              type="text"
              maxLength={42}
              placeholder={`Reconfirm ${digitalAsset} wallet address`}
              onChange={handleChange}
              value={refundVal.reconfirmWalletAddress}
              name="reconfirmWalletAddress"
            />
            <Form.Text className="text-muted">
              to be filled by customer
            </Form.Text>
          </Form.Group>
          <h4>
            Declaration signed by the customer to the effect that (Customer to
            accept and sign)
          </h4>
          <ul className="list-details">
            <li>
              Refund will be in {digitalAsset}, into a {digitalAsset} wallet
            </li>
            <li>Gas fee will be borne by the Customer</li>
            <li>It may take up to 1 weeks for the refund to take place</li>
            <li>
              Refund amount could be less than the original transaction amount
              (equivalent)due to
              <br />
              gas fee, conversion from the original crypto currency to{" "}
              {digitalAsset} and the conversion rate at <br />
              the time of execution of the refunded transaction{" "}
            </li>
          </ul>
          <Form.Group className="mb-3 mt-4" controlId="formBasicCheckbox">
            <div style={{ display: "flex" }}>
              <Form.Check
                type="checkbox"
                checked={checkVal}
                onChange={() => setCheckVal(!checkVal)}
                label={`I accept the `}
              />
              <button
                type="button"
                className="buttonToLink"
                onClick={() =>
                  setShowTermsAndConditionsForm(!showTermsAndConditionsForm)
                }
              >
                Terms and Conditions
              </button>
            </div>
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            disabled={!checkVal}
            // disabled={
            //   (refundVal.refundUserName &&
            //     !refundVal.refundUserName.match(/^[A-Za-z]+$/)) ||
            //   (refundVal.refundWalletAddress &&
            //     (refundVal.refundWalletAddress.length < 24 ||
            //       refundVal.refundWalletAddress.length > 32))
            //     ? true
            //     : false
            // }
            onClick={submitData}
          >
            Submit
          </Button>
          <Button variant="secondary" className="ml-4" onClick={onCancelClick}>
            Cancel
          </Button>
        </Form>
      )}
      {showTermsAndConditionsForm ? (
        <TermsAndConditionsPopupModal
          showhide={() => setShowTermsAndConditionsForm(false)}
        />
      ) : null}
    </div>
  )
}
