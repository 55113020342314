/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { PropsWithChildren, useEffect, useState } from "react"
import { Hub, HubCapsule } from "@aws-amplify/core"

import { getCurrentUserAsync, User } from "../auth/AuthManager"
import { FiatAsset } from "../constants/types"

type UserContextType = {
  user: User
  isLoading: boolean
  fiatAsset: FiatAsset
  setFiatAsset: (value: FiatAsset) => void
}

export const UserContext = React.createContext<UserContextType>({
  user: null,
  isLoading: true,
  fiatAsset: "AED",
  setFiatAsset: () => {}
})

type Props = PropsWithChildren<{}>

export const UserContextProvider: React.FC<Props> = ({ children }: Props) => {
  const [user, setUser] = useState<User>()
  const [isLoading, setIsLoading] = useState(true)
  const [fiatAsset, setFiatAsset] = useState<FiatAsset>("AED")

  const listener = async (data: HubCapsule) => {
    switch (data.payload.event) {
      case "signIn":
        setUser(await getCurrentUserAsync())
        break
      case "signUp":
        setUser(await getCurrentUserAsync())
        break
      case "signOut":
        setUser(null)
        break
      case "signIn_failure":
        break
      case "tokenRefresh":
        break
      case "tokenRefresh_failure":
        setUser(null)
        break
      case "configured":
        console.log("The Auth module is configured")
    }
  }

  useEffect(() => {
    const defaultCurrency = localStorage.getItem("default-currency")
    if (defaultCurrency) {
      setFiatAsset(JSON.parse(defaultCurrency || "AED"))
    } else {
      setFiatAsset("AED")
    }
  }, [fiatAsset])

  useEffect(() => {
    const bootstrapUser = async () => {
      setUser(await getCurrentUserAsync())
      setIsLoading(false)
    }

    Hub.listen("auth", listener)
    bootstrapUser()
    return () => {
      Hub.remove("auth", listener)
    }
  }, [])

  return (
    <UserContext.Provider value={{ user, isLoading, fiatAsset, setFiatAsset }}>
      {children}
    </UserContext.Provider>
  )
}
