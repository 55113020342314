import React from "react"
import { BrowserRouter, Link, Route, Routes } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "react-query"

import "./App.scss"
import "./i18n"
import { OnboardingContextProvider } from "./context"
import { UserContextProvider } from "./context/User"
import PrivateRoute from "./auth/PrivateRoute"
import PublicRoute from "./auth/PublicRoute"
import SignOut from "./components/ui/SignOut"
import ResetSuccess from "./screens/ResetPassword/ResetSuccess"
import UpdateSuccess from "./screens/Settings/UpdatePassword/UpdateSuccess"
import RequestResetPasswordCode from "./screens/ResetPassword/RequestResetPasswordCode"
import SubmitResetPasswordCode from "./screens/ResetPassword/SubmitResetPasswordCode"
import VerifyPhoneCode from "./screens/Onboarding/VerifyPhoneCode"
import VerifyEmailCode from "./screens/Onboarding/VerifyEmailCode"
import { RouteType } from "./constants/routeTypes"
import Success from "./screens/Onboarding/Success"
import AccountDetails from "./screens/Onboarding/AccountDetails"
import Dashboard from "./screens/Dashboard/Dashboard"
import Page404 from "./screens/Error/Page404"
import CreateAccount from "./screens/Onboarding/CreateAccount"
import SignIn from "./screens/SignIn"
import Transactions from "./screens/Transactions/Transactions"
import TransactionDetail from "./screens/Dashboard/TransactionDetails"
import Invoice from "./screens/Invoice/Invoice"
import Settings from "./screens/Settings/Settings"
import MerchantDetails from "./screens/Onboarding/MerchantDetails"
import P2PTransaction from "./screens/Dashboard/P2PTransaction"
import Admin from "./screens/Admin"
import Refund from "./screens/Refund/Refund"
import RefundReqAcceptance from "./screens/RefundAcceptance/RefundReqAcceptance"
import RefundReqApproval from "./screens/RefundApproval/RefundReqApproval"
import AdminUsers from "./screens/Admin/Users"
import ChangePassword from "./screens/Settings/UpdatePassword/ChangePassword"
import AdminInviteUser from "./screens/Admin/InviteUser"
import AdminAPIKeys from "./screens/Admin/APIKeys"
import AdminCreateAPIKey from "./screens/Admin/CreateAPIKey"
import AcceptInvite from "./screens/Onboarding/AcceptInvite"
import RefundFormManual from "./screens/RefundForm/RefundFormManual"
import { MerchantContextProvider } from "./context/Merchant"
import MerchantName from "./components/ui/MerchantName"
import Navigation from "./components/navigation/Navigation"
import RefundForm from "./screens/RefundForm/RefundForm"
import CustomerRefundForm from "./screens/RefundForm/CustomerRefundForm"
import RequestTransaction from "./screens/RequestTransaction/requestTransaction"
import ATM from "./screens/ATM/ATM"
import ATMEntry from "./screens/ATM/ATMEntry"
import ATMQrCode from "./screens/ATM/ATMQrCode"
import ATMDisbursement from "./screens/ATM/ATMDisbursement"
import { WADZPAY_LOGO } from "./constants/Defaults"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

function App(): JSX.Element {
  return (
    <div className="App">
      <UserContextProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <MerchantContextProvider>
              {/* <OnboardingContextProvider>
                <Routes>
                  <Route
                    path={RouteType.REFUND_FORM_TOKEN}
                    element={<CustomerRefundForm />}
                  ></Route>
                </Routes>
              </OnboardingContextProvider> */}
              <div className="container-fluid mb-4">
                <div
                  className={
                    window.location.pathname !== RouteType.REFUND_FORM_TOKEN
                      ? `row dashBoardBg`
                      : `refundBg`
                  }
                >
                  <div
                    className="col-md-2 navigation-container"
                    id="navigation-container"
                  >
                    <Navigation />
                  </div>
                  <div className="header mb-4" id="topHeader">
                    <div className="container-fluid d-flex justify-content-between header-row align-items-center mt-2">
                      <div className="d-flex align-items-center">
                        <div className="logo-container">
                          {WADZPAY_LOGO ? (
                            <picture>
                              <source
                                // srcSet="/images/dubai.png"
                                srcSet="/images/white-logo.png"
                                type="image/webp"
                              />
                              <img
                                // src="/images/white-logo.png"
                                src="/images/white-logo.png"
                                alt="WadzPay Logo"
                                title="WadzPay Logo"
                              />
                            </picture>
                          ) : (
                            <picture>
                              <source
                                // srcSet="/images/dubai.png"
                                srcSet="/images/Dubai_Duty_logo_white.png"
                                type="image/webp"
                              />
                              <img
                                // src="/images/white-logo.png"
                                src="/images/Dubai_Duty_logo_white.png"
                                alt="DDF Logo"
                                title="DDF Logo"
                                width="65px"
                              />
                            </picture>
                          )}
                        </div>
                        <div className="mx-3" data-testid="header">
                          Merchant Dashboard
                        </div>
                        <div
                          className="mx-3 text-nowrap"
                          data-testid="merchantName"
                        >
                          {/* <MerchantName /> */}
                        </div>
                      </div>
                      <div className="d-flex justify-content-end me-1 text-nowrap align-items-right flex-column mt-3">
                        <SignOut />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-10 main-container" id="main-container">
                    <div className="container-body">
                      <OnboardingContextProvider>
                        <Routes>
                          {/* Public route */}
                          <Route element={<PublicRoute />}>
                            <Route
                              path={RouteType.VERIFY_PHONE_CODE}
                              element={<VerifyPhoneCode />}
                            />

                            {/* <Route
                              path={RouteType.REFUND_FORM_TOKEN}
                              element={<CustomerRefundForm />}
                            ></Route> */}

                            <Route
                              path={RouteType.ONBOARDING_SUCCESS}
                              element={<Success />}
                            />
                            <Route
                              path={RouteType.ACCOUNT_DETAILS}
                              element={<AccountDetails />}
                            />
                            <Route
                              path={RouteType.VERIFY_EMAIL_CODE}
                              element={<VerifyEmailCode />}
                            />
                            <Route
                              path={RouteType.CREATE_ACCOUNT}
                              element={<CreateAccount />}
                            />

                            <Route
                              path={RouteType.SIGN_IN}
                              element={<SignIn />}
                            />

                            <Route
                              path={RouteType.ACCEPT_INVITE}
                              element={<AcceptInvite />}
                            ></Route>
                          </Route>

                          {/* Private route */}
                          <Route element={<PrivateRoute />}>
                            <Route
                              path={RouteType.HOME}
                              element={<Dashboard />}
                            />
                            <Route
                              path={`${RouteType.TRANSACTIONS}`}
                              element={<Transactions />}
                            />
                            <Route
                              path={`${RouteType.TRANSACTION_DETAIL}/:transactionId`}
                              element={<TransactionDetail />}
                            />
                            <Route
                              path={RouteType.INVOICE}
                              element={<Invoice />}
                            ></Route>
                            {/* <Route
                              path={RouteType.REFUND_DISPUTE}
                              element={<P2PTransaction />}
                            >
                              <Route
                                path={`${RouteType.REFUND_DISPUTE}/:transactionId`}
                                element={<P2PTransaction />}
                              />
                            </Route> */}
                            <Route
                              path={RouteType.REFUND_DISPUTE}
                              element={<Refund />}
                            />
                            <Route
                              path={RouteType.REFUND_REQUEST_ACCEPTANCE}
                              element={<RefundReqAcceptance />}
                            />
                            <Route
                              path={RouteType.REFUND_REQUEST_APPROVAL}
                              element={<RefundReqApproval />}
                            />
                            <Route
                              path={RouteType.SETTINGS}
                              element={<Settings />}
                            />
                            <Route
                              path={RouteType.ADMIN_USERS}
                              element={<AdminUsers />}
                            />
                            <Route
                              path={RouteType.ADMIN_USERS_INVITE}
                              element={<AdminInviteUser />}
                            />
                            <Route
                              path={RouteType.ADMIN_API_KEYS}
                              element={<AdminAPIKeys />}
                            />
                            <Route
                              path={RouteType.ADMIN_API_KEYS_CREATE}
                              element={<AdminCreateAPIKey />}
                            />

                            <Route
                              path={RouteType.MERCHANT_DETAILS}
                              element={<MerchantDetails />}
                            />

                            <Route
                              path={RouteType.CHANGE_PASSWORD}
                              element={<ChangePassword />}
                            />

                            <Route
                              path={RouteType.CUSTOMER_MANUAL_VERIFICATION_FORM}
                              element={<RefundFormManual />}
                            />
                          </Route>

                          {/* Private Route or public route */}
                          <Route
                            path={RouteType.RESET_PASSWORD}
                            element={<RequestResetPasswordCode />}
                          />
                          <Route
                            path={RouteType.SUBMIT_RESET_PASSWORD}
                            element={<SubmitResetPasswordCode />}
                          />
                          <Route
                            path={RouteType.RESET_PASSWORD_SUCCESS}
                            element={<ResetSuccess />}
                          />
                          <Route
                            path={RouteType.CHANGE_PASSWORD_SUCCESS}
                            element={<UpdateSuccess />}
                          />
                          <Route
                            path={RouteType.REFUND_FORM_TOKEN}
                            element={<CustomerRefundForm />}
                          ></Route>
                          <Route path={RouteType.ATM} element={<ATM />}></Route>
                          <Route
                            path={RouteType.ATM_ENTRY}
                            element={<ATMEntry />}
                          ></Route>
                          <Route
                            path={RouteType.ATM_ENTRY_QR_CODE}
                            element={<ATMQrCode />}
                          ></Route>
                          <Route
                            path={RouteType.ATM_DISBURSEMENT}
                            element={<ATMDisbursement />}
                          ></Route>
                          {/* <Route
                            path={RouteType.RequestTransaction}
                            element={<RequestTransaction />}
                          ></Route>*/}
                          {!RouteType.REFUND_FORM_TOKEN && (
                            <Route path="*" element={<Page404 />} />
                          )}
                        </Routes>
                      </OnboardingContextProvider>
                    </div>
                  </div>
                </div>
              </div>
            </MerchantContextProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </UserContextProvider>
    </div>
  )
}

export default App
