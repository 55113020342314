import React, { ReactNode, useContext } from "react"
import { UserContext } from "src/context/User"

const SignedInUser: React.FC = ({ children }: { children?: ReactNode }) => {
  const { user, isLoading } = useContext(UserContext)

  return <>{!isLoading && user && children}</>
}

export default SignedInUser
