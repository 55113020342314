import React, { useEffect, useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { Button } from "react-bootstrap"
import { RouteType } from "src/constants/routeTypes"
import {
  useRefundTransactions,
  useRefundTransactionsNoPage
} from "src/api/user"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import tz from "dayjs/plugin/timezone"
import { Dropdown } from "react-bootstrap"
import jsPDF from "jspdf"
import "jspdf-autotable"
import autoTable from "jspdf-autotable"
import Modal from "react-bootstrap/Modal"
import { BsInfoCircleFill } from "react-icons/bs"
import { Transaction } from "src/api/models"
import useFormatCurrencyAmount from "src/helpers/formatCurrencyAmount"
import Pagination from "src/helpers/pagination"
import { useSortIcons } from "src/components/ui/useSortIcons"
import { useRefundFilterSelectField } from "src/components/ui/useRefundFilterSelectField"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import Card from "src/helpers/Card"
import { UserContext } from "src/context/User"
import { MerchantContext } from "src/context/Merchant"

import InitiateRefundPopupModal from "../../components/ui/PopUps/InitiateRefundPopupModal"

type State = {
  page: number
  sortBy: string
  sortDirection: string
  filter: { [key: string]: string }
}

const defaults = {
  page: 1,
  sortBy: "CREATED_AT",
  sortDirection: "DESC"
}
const filterKeys = [
  "asset",
  "status",
  "type",
  "direction",
  "dateFrom",
  "dateTo",
  "refundMode"
]

const RefundTransactions: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const formatter = useFormatCurrencyAmount()
  const { user } = useContext(UserContext)
  const { merchantDetails } = useContext(MerchantContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const [show, setShow] = useState(false)
  const [merchantType, setMerchantType] = useState("")

  dayjs.extend(utc)
  dayjs.extend(tz)

  const filters: { [key: string]: string } = {}
  searchParams.forEach((value, key) => {
    if (filterKeys.includes(key)) {
      filters[key] = value
    }
  })

  const [state, setState] = useState<State>({
    page: parseInt(searchParams.get("page") || `${defaults.page}`, 10),
    sortBy: searchParams.get("sortBy") || defaults.sortBy,
    sortDirection: searchParams.get("sortDirection") || defaults.sortDirection,
    filter: filters
  })

  const urlSearchParams = new URLSearchParams()
  Object.entries(state.filter).forEach(([key, value]) =>
    urlSearchParams.append(key, value)
  )

  let apiParams

  if (user && user.attributes.email == "ddf.pilot@wadzpay.com") {
    apiParams = {
      ...{
        sortBy: state.sortBy,
        sortDirection: state.sortDirection,
        asset: "USDT, ETH"
      },
      ...state.filter
    }
  } else {
    apiParams = {
      ...{
        sortBy: state.sortBy,
        sortDirection: state.sortDirection
      },
      ...state.filter
    }
  }

  const apiQueryParams = new URLSearchParams()
  Object.entries(apiParams).forEach(([key, value]) =>
    apiQueryParams.append(key, value)
  )

  const {
    data: transactionData,
    isFetching: isFetchingTransactions,
    error: errorTransactions
  } = useRefundTransactions(
    `page=${state.page - 1}&${apiQueryParams.toString()}`
  )

  useEffect(() => {
    if (merchantDetails?.role) {
      setMerchantType(merchantDetails.role)
    }
  }, [merchantDetails])

  const { data: transactionDataNext } = useRefundTransactions(
    `page=${state.page}&${apiQueryParams.toString()}`
  )

  const { data: transactionDataNoPage } = useRefundTransactionsNoPage(
    `${apiQueryParams.toString()}`
  )

  const [loading, setLoading] = useState(isFetchingTransactions)

  const FilterSection = useRefundFilterSelectField({
    submitFilter: (filters) => {
      // setState({ ...state, ...{ filter: filters } })
      setState({
        ...state,
        ...{ page: 1 },
        ...{ filter: filters }
      })
    },
    filters
  })

  useEffect(() => {
    setLoading(isFetchingTransactions)
  }, [isFetchingTransactions])

  useEffect(() => {
    setSearchParams({
      ...(state.page !== defaults.page && {
        page: `${state.page}`
      }),
      ...(state.sortBy !== defaults.sortBy && {
        sortBy: state.sortBy
      }),
      ...(state.sortDirection !== defaults.sortDirection && {
        sortDirection: state.sortDirection
      }),
      ...state.filter
    })
  }, [state.page, state.sortBy, state.sortDirection, state.filter])

  const sortData = (value: string) => {
    setLoading(true)
    if (value !== state.sortBy) {
      setState({
        ...state,
        ...{ page: 1, sortBy: value, sortDirection: "ASC" }
      })
    } else if (state.sortDirection === "ASC") {
      setState({ ...state, ...{ page: 1, sortDirection: "DESC" } })
    } else {
      setState({ ...state, ...{ page: 1, sortDirection: "ASC" } })
    }
  }

  const SortIcon = useSortIcons({
    sortData,
    sortedField: state.sortBy,
    sortedDirection: state.sortDirection
  })

  const navigatePage = (transactionId: string) => {
    navigate(`${RouteType.TRANSACTION_DETAIL}/${transactionId}`, {
      state: { from: location.pathname + location.search }
    })
  }

  const downloadTransactionsCSV = () => {
    if (transactionData) {
      const data = [...transactionData]
      // console.log(data)
      JSONToCSVConvertor(data, "Transactions", true)
    }
  }

  const downloadTransactionsPDF = () => {
    if (transactionData) {
      const currentData = [...transactionData]
      const head = [
        [
          "Sl.No",
          "Transaction ID",
          "POS Transaction ID",
          "Date",
          "Asset",
          "Received Digital Amount",
          "Received Fiat Amount",
          "Refund Value",
          "Refund Mode"
        ]
      ]
      const finalData: any = []
      currentData.map((item, index) => {
        const arr = []
        arr.push(index + 1)
        if (Object.prototype.hasOwnProperty.call(item, "uuid")) {
          arr.push(item.uuid)
        }
        if (Object.prototype.hasOwnProperty.call(item, "extPosTransactionId")) {
          arr.push(item.extPosTransactionId)
        }
        if (Object.prototype.hasOwnProperty.call(item, "createdAt")) {
          arr.push(dayjs(item.createdAt).format("MMMM D, YYYY h:mm A"))
        }
        if (Object.prototype.hasOwnProperty.call(item, "asset")) {
          arr.push(item.asset)
        }
        if (
          Object.prototype.hasOwnProperty.call(
            item,
            "totalDigitalCurrencyReceived"
          )
        ) {
          arr.push(item.totalDigitalCurrencyReceived)
        }
        if (Object.prototype.hasOwnProperty.call(item, "totalFiatReceived")) {
          arr.push(`${item.totalFiatReceived} ${item.fiatAsset}`)
        }
        if (Object.prototype.hasOwnProperty.call(item, "refundFiatAmount")) {
          arr.push(item.refundStatus == "REFUNDED" ? item.refundFiatAmount : 0)
        }
        if (Object.prototype.hasOwnProperty.call(item, "refundMode")) {
          if (item.refundStatus === "REFUNDED") {
            arr.push(item.refundMode)
          } else {
            arr.push("NA")
          }
        }
        finalData.push(arr)
      })
      const doc = new jsPDF()
      autoTable(doc, {
        head: head,
        body: finalData,
        columnStyles: {
          0: { cellWidth: 10 },
          1: { cellWidth: 28 },
          5: { cellWidth: 15 }
        }
      })

      doc.save("myReports-Transactions.pdf")
    }
  }

  const JSONToCSVConvertor = (
    JSONData: any,
    ReportTitle: string,
    ShowLabel: boolean
  ) => {
    const arrData =
      typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData
    let CSV = ""
    const headersRequried = [
      "uuid",
      "extPosTransactionId",
      "createdAt",
      "asset",
      "totalDigitalCurrencyReceived",
      "totalFiatReceived",
      "refundFiatAmount",
      "refundMode"
    ]
    if (ShowLabel) {
      let row = ""
      for (const index in arrData[0]) {
        switch (index) {
          case "uuid":
            row += "Transaction ID" + ","
            break
          case "extPosTransactionId":
            row += "POS Transaction ID" + ","
            break
          case "createdAt":
            row += "Date" + ","
            break
          case "asset":
            row += "Asset" + ","
            break
          case "totalDigitalCurrencyReceived":
            row += "Received Digital Amount" + ","
            break
          case "totalFiatReceived":
            row += "Received Fiat Amount" + ","
            break
          case "refundFiatAmount":
            row += "Refund Value" + ","
            break
          case "refundMode":
            row += "Refund Mode" + ","
            break
          default:
            break
        }
      }
      row = row.slice(0, -1)
      CSV += row + "\r\n"
    }
    for (let i = 0; i < arrData.length; i++) {
      let row = ""
      for (const index in arrData[i]) {
        if (headersRequried.includes(index)) {
          if (index == "createdAt") {
            row +=
              '"' +
              dayjs(arrData[i][index]).format("MMMM D, YYYY h:mm A") +
              '",'
          } else if (
            index == "feeAmount" ||
            index == "totalDigitalCurrencyReceived" ||
            index == "amount"
          ) {
            row += '"' + arrData[i][index] + '",'
          } else if (index == "totalFiatReceived") {
            // row += '"' + arrData[i][index] + '"' + '"' + arrData[i]["fiatAsset"] + '",'
            row += `${
              arrData[i]["refundStatus"] === "REFUNDED"
                ? arrData[i][index]
                : "0"
            } ${arrData[i]["fiatAsset"]},`
          } else if (index === "refundMode") {
            row += `${
              arrData[i]["refundStatus"] === "REFUNDED"
                ? arrData[i]["refundMode"]
                : "NA"
            },`
          } else {
            row += '"' + arrData[i][index] + '",'
          }
        }
      }
      row.slice(0, row.length - 1)
      CSV += row + "\r\n"
    }
    if (CSV === "") {
      return
    }
    let fileName = "MyReport_"
    fileName += ReportTitle.replace(/ /g, "_")
    const uri = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(CSV)
    const link = document.createElement("a")
    link.href = uri
    link.style.visibility = "hidden"
    link.download = fileName + ".csv"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  const paginate = (page: number) => {
    setLoading(true)
    setState({ ...state, ...{ page } })
  }

  const checkIcon = (iconName: string) => {
    switch (iconName) {
      case "IN_PROGRESS":
        return (
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-disabled">{t("In Progress")}</Tooltip>
            }
          >
            <img
              src={"/images/transactions/inprogress.png"}
              alt={"In Progress"}
              width="20px"
              height="20px"
              style={{ marginLeft: "20px" }}
            />
          </OverlayTrigger>
        )
        break
      case "SUCCESSFUL":
        return (
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">{t("Success")}</Tooltip>}
          >
            <img
              src={"/images/transactions/success.png"}
              alt={"success"}
              width="20px"
              height="20px"
              style={{ marginLeft: "20px" }}
            />
          </OverlayTrigger>
        )
        break
      case "FAILED":
        return (
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">{t("Failed")}</Tooltip>}
          >
            <img
              src={"/images/transactions/failed.png"}
              alt={"Failed"}
              width="20px"
              height="20px"
              style={{ marginLeft: "20px" }}
            />
          </OverlayTrigger>
        )
        break
      case "OVERPAID":
        return (
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">{t("Overpaid")}</Tooltip>}
          >
            <img
              src={"/images/transactions/overpaid.png"}
              alt={"Failed"}
              width="20px"
              height="20px"
              style={{ marginLeft: "20px" }}
            />
          </OverlayTrigger>
        )
        break
      case "UNDERPAID":
        return (
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">{t("Underpaid")}</Tooltip>}
          >
            <img
              src={"/images/transactions/underpaid.png"}
              alt={"Failed"}
              width="20px"
              height="20px"
              style={{ marginLeft: "20px" }}
            />
          </OverlayTrigger>
        )
        break
      default:
        return iconName
        break
    }
  }

  const dateFormat = (time: any) => {
    const localStorageTime = localStorage.getItem("TimeZone")
    if (localStorageTime) {
      const timezone = JSON.parse(localStorageTime).value || "Asia/Dubai"
      return dayjs(time).tz(timezone).format("DD-MM-YYYY HH:mm:ss")
    } else {
      const timezone = "Asia/Dubai"
      return dayjs(time).tz(timezone).format("DD-MM-YYYY HH:mm:ss")
    }
  }

  const dateTimeFormat = (time: any) => {
    if (!time) {
      return null
    }
    const localStorageTime = localStorage.getItem("TimeZone")
    if (localStorageTime) {
      const timezone = JSON.parse(localStorageTime).value || "Asia/Dubai"
      return dayjs(time).tz(timezone).format("DD-MM-YYYY HH:mm:ss")
    } else {
      return dayjs(time).tz("Asia/Dubai").format("DD-MM-YYYY HH:mm:ss")
    }
  }

  const trxIdReturn = (obj: any) => {
    if (obj.description && obj.uuid == "") {
      return null
    }

    if (obj.description && obj.uuid == null) {
      return null
    }

    if (
      obj.description &&
      obj.description.includes("-") &&
      obj.description.length == 36
    ) {
      return obj.description
    }

    return obj.uuid
  }

  const checkRefundBtnType = (trx: any) => {
    const type = trx.refundStatus
    const id = trxIdReturn(trx)
    if (type == null || type == "NULL") {
      const trxobj = {
        uuid: "d51b0fc5-df1b-474e-87b0-167bceb8aeea",
        order_uuid: null,
        external_order_id: null,
        createdAt: "2022-06-29T14:03:39.769275Z",
        amount: 0.00005541,
        asset: "BTC",
        fiatAmount: 1.11,
        fiatAsset: "USD",
        status: "SUCCESSFUL",
        transactionType: "POS",
        senderName: "External",
        receiverName: "NewMerchantAbhinav",
        direction: "INCOMING",
        description: "string",
        totalAmount: 0.00005541,
        feePercentage: 0,
        feeAmount: 0,
        blockchainTxId: null,
        trxId: "POS-920",
        totalDigitalCurrencyReceived: 0.00005541,
        refundStatus: "REFUND_APPROVED",
        refundUserName: null,
        refundUserMobile: null,
        refundUserEmail: null,
        refundFiatType: null,
        refundWalletAddress: null,
        refundDigitalCurrencyType: null,
        refundReason: null,
        refundAcceptanceComment: "string",
        refundApprovalComment: null,
        refundFiatAmount: 500,
        refundType: "NA",
        id: "d51b0fc5-df1b-474e-87b0-167bceb8aeea"
      }
      return (
        <InitiateRefundPopupModal
          txt="Refund"
          color="btn btn-success btn-sm"
          id={id}
          rowtrx={trx}
          disabled={false}
          apicallstatus={() => window.location.reload()}
        />
      )
    }
    if (type == "REFUNDED") {
      return (
        <InitiateRefundPopupModal
          txt="Refund"
          color="btn btn-sm refundbtn refundbtnOpacity"
          id={id}
          rowtrx={trx}
          disabled={true}
        />
      )
    }
    if (
      type == "REFUND_INITIATED" ||
      type == "REFUND_ACCEPTED" ||
      type == "REFUND_APPROVED"
    ) {
      return (
        <InitiateRefundPopupModal
          txt="Refund"
          color="btn btn-primary btn-sm refundbtnOpacity"
          id={id}
          rowtrx={trx}
          disabled={true}
        />
      )
    }
    if (
      type == "REFUND_FAILED" ||
      type == "REFUND_CANCELED" ||
      type == "REFUND_EXPIRED"
    ) {
      return (
        <InitiateRefundPopupModal
          txt="Refund"
          color="btn btn-danger btn-sm refundbtnOpacity"
          id={id}
          rowtrx={trx}
          disabled={true}
        />
      )
    }
  }

  const handleClose = () => setShow(false)

  const renderAcceptRejectButtons = () => {
    if (
      merchantType == "MERCHANT_MERCHANT" ||
      merchantType == "MERCHANT_ADMIN" ||
      merchantType == "MERCHANT_READER"
    ) {
      return (
        <>
          <button
            className="btn btn-secondary"
            onClick={() => navigate(RouteType.REFUND_REQUEST_ACCEPTANCE)}
          >
            {t("Refund Request Acceptance")}
          </button>
          <button
            className="btn btn-secondary"
            style={{ marginLeft: "10px" }}
            onClick={() => navigate(RouteType.REFUND_REQUEST_APPROVAL)}
          >
            {t("Refund Request Approval")}
          </button>
        </>
      )
    }
    if (merchantType == "MERCHANT_SUPERVISOR") {
      return (
        <button
          className="btn btn-secondary"
          onClick={() => navigate(RouteType.REFUND_REQUEST_ACCEPTANCE)}
        >
          {t("Refund Request Acceptance")}
        </button>
      )
    }
    return null
  }

  return (
    <>
      <h3>Refund</h3>
      <div className="col-md-12 mb-4">
        {merchantDetails && renderAcceptRejectButtons()}
      </div>
      <Card>
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          className="mt-1"
        >
          <FilterSection />
          <Dropdown>
            <Dropdown.Toggle variant="success" className="wdz-btn-primary">
              Download Report
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => downloadTransactionsCSV()}>
                CSV
              </Dropdown.Item>
              <Dropdown.Item onClick={() => downloadTransactionsPDF()}>
                PDF
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="table-responsive mt-4" style={{ overflowX: "visible" }}>
          <table className="table transactions-table">
            <thead>
              <tr>
                <th scope="col">{t("Transaction ID")}</th>
                <th scope="col">{t("POS Transaction ID")}</th>
                <SortIcon
                  element="Date"
                  sortField="CREATED_AT"
                  wordLabel="Date"
                />
                <th scope="col">{t("Asset")}</th>
                <SortIcon
                  element="Amount"
                  sortField="AMOUNT"
                  wordLabel="Received Digital Amount"
                />
                <th scope="col" style={{ textAlign: "center" }} colSpan={2}>
                  {t("Received Fiat Amount")}
                </th>
                {/*<SortIcon
                  element="Status"
                  sortField="STATUS"
                  wordLabel="Status"
                />*/}
                <th scope="col" style={{ textAlign: "right" }}>
                  {t("Refund Value(Fiat)")}
                </th>
                <th scope="col" style={{ textAlign: "right" }}>
                  {t("Refund Value in USDT")}
                </th>
                <th scope="col" style={{ textAlign: "right" }}>
                  {t("Refund Mode")}
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                  {t("Refund")}
                  <span
                    style={{ marginLeft: "8px", cursor: "pointer" }}
                    onClick={() => setShow(true)}
                    title="Button color info"
                  >
                    <BsInfoCircleFill />
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {!errorTransactions &&
                transactionData &&
                transactionData.length > 0 &&
                transactionData.map((transaction: Transaction) => [
                  <tr
                    key={transaction.id}
                    // onClick={() => navigatePage(transaction.id)}
                    // style={{ cursor: "pointer" }}
                  >
                    <td>
                      {/* {transaction.direction === "INCOMING"
                        ? transaction.senderName
                        : transaction.receiverName} */}
                      {trxIdReturn(transaction)}
                    </td>
                    <td style={{ wordBreak: "break-all" }}>
                      {transaction.extPosTransactionId}
                    </td>
                    <td>
                      {/* {dayjs(transaction.createdAt).format(
                        "MMMM D, YYYY h:mm A"
                      )} */}
                      {transaction.paymentReceivedDate != undefined
                        ? dateTimeFormat(transaction.paymentReceivedDate)
                        : ""}
                    </td>
                    <td>
                      {/* <img
                        src={"/images/" + transaction.asset + ".svg"}
                        alt={transaction.asset}
                        width="20px"
                        height="20px"
                        style={{ marginRight: "5px" }}
                      /> */}
                      <span className="fiatAssetFontSize">
                        {transaction.asset && transaction.asset}
                      </span>
                    </td>
                    <td>
                      {formatter(transaction.totalDigitalCurrencyReceived, {
                        asset: transaction.asset
                      })}
                    </td>
                    <td>
                      <span style={{ float: "right" }}>
                        {transaction.totalFiatReceived &&
                          transaction.totalFiatReceived.toFixed(2)}
                      </span>
                    </td>
                    <td style={{ paddingLeft: "0" }}>
                      <span className="fiatAssetFontSize">
                        {transaction.fiatAsset}
                      </span>
                    </td>
                    {/* <td>{checkIcon(transaction.status)}</td> */}
                    {/*  <td>{transaction.status}</td>*/}
                    <td>
                      <span style={{ float: "right" }}>
                        {transaction.fiatAsset}
                      </span>
                      <span style={{ float: "right" }}>
                        {transaction.refundFiatAmount &&
                        transaction?.refundStatus === "REFUNDED"
                          ? transaction.refundFiatAmount
                          : 0}
                      </span>
                    </td>
                    <td>
                      <span style={{ float: "right" }}>
                        {transaction.refundAmountDigital}
                      </span>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <span>
                        {transaction.refundMode &&
                        transaction?.refundStatus === "REFUNDED"
                          ? transaction.refundMode
                          : "NA"}
                      </span>
                    </td>
                    <td>{checkRefundBtnType(transaction)}</td>
                  </tr>
                ])}
              <tr className="transaction-count">
                <td colSpan={5}>
                  <p style={{ margin: 0 }}>
                    {transactionDataNoPage && transactionDataNoPage.length == 0
                      ? `No data available`
                      : null}
                    {transactionDataNoPage &&
                    transactionDataNoPage.length > 0 &&
                    transactionDataNoPage.length <= 9
                      ? `Showing ${
                          transactionDataNoPage && transactionDataNoPage.length
                        } item${
                          transactionDataNoPage &&
                          transactionDataNoPage.length == 1
                            ? ""
                            : "s"
                        }`
                      : null}
                    {transactionDataNoPage && transactionDataNoPage.length > 9
                      ? `Showing ${
                          state.page == 1 ? 1 : (state.page - 1) * 10 + 1
                        } to ${
                          state.page == 1
                            ? 10
                            : transactionData && transactionData.length > 9
                            ? transactionData.length * (state.page - 1) + 10
                            : transactionData &&
                              transactionData.length + state.page * 10 - 10
                        }${""}
                      ${
                        transactionData && transactionData.length == 1
                          ? "item"
                          : "items"
                      } 
                      from ${
                        transactionDataNoPage == undefined
                          ? 0
                          : transactionDataNoPage &&
                            transactionDataNoPage.length
                      }`
                      : null}
                  </p>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          {transactionData && (
            <Pagination
              paginate={paginate}
              currPage={state.page}
              nextPage={transactionDataNext?.length === 0}
              loading={loading}
              alltransactionlength={transactionDataNoPage?.length || 0}
            />
          )}
          {errorTransactions && (
            <div
              className="alert alert-danger"
              role="alert"
              data-testid="errorMessage"
            >
              {errorTransactions.message}
            </div>
          )}
        </div>
      </Card>
      <>
        <Modal show={show} onHide={handleClose} centered backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Buttons color info</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table transactions-table">
              <thead>
                <tr>
                  <th>Button Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <button className="btn btn-success btn-sm" disabled>
                      {t("Refund")}
                    </button>
                  </td>
                  <td>{t("Yet to start")}</td>
                </tr>
                <tr>
                  <td>
                    <button className="btn refundbtn refundbtnOpacity" disabled>
                      {t("Refund")}
                    </button>
                  </td>
                  <td>{t("Refunded")}</td>
                </tr>
                <tr>
                  <td>
                    <button
                      className="btn btn-primary btn-sm refundbtnOpacity"
                      disabled
                    >
                      {t("Refund")}
                    </button>
                  </td>
                  <td>{t("In Progress")}</td>
                </tr>
                <tr>
                  <td>
                    <button
                      className="btn btn-danger btn-sm refundbtnOpacity"
                      disabled
                    >
                      {t("Refund")}
                    </button>
                  </td>
                  <td>{t("Rejected")}</td>
                </tr>
              </tbody>
            </table>
          </Modal.Body>
        </Modal>
      </>
    </>
  )
}

export default RefundTransactions
