import { FiatTokenToAmount, TokenToAmount } from "src/constants/types"

export enum EndpointKey {
  SEND_PHONE_OTP = "sendPhoneOTP",
  VERIFY_EMAIL_OTP_CREATE_USER = "verifyEmailOTPAndCreateUser",
  GET_USER = "getUser",
  VERIFY_PHONE_OTP = "verifyPhoneOTP",
  USER_DETAILS_SEND_EMAIL_OTP = "userDetailsAndEmailOTP",
  USER_BALANCES = "userBalances",
  MERCHANT_TRANSACTION = "merchantTransaction",
  MERCHANT_TRANSACTIONS = "merchantTransactions",
  REFUND_TRANSACTIONS = "refundTransactions",
  REFUND_ACCEPT_REJECT_TRANSACTIONS = "refundAcceptRejectTransactions",
  REFUND_INITIATE_WEBLINK = "refundInitiateWebLink",
  REFUND_SUBMIT_WITHAUTH = "refundSubmitFormWithAuth",
  EXCHANGE_RATES = "exchangeRates",
  USER_TRANSACTION = "userTransaction",
  USER_TRANSACTIONS = "userTransactions",
  MERCHANT_DETAILS = "merchantDetails",
  P2P_TRANSACTIONS = "p2pTransaction",
  GENERATE_API_KEY = "generateAPIKey",
  INVITE_USER = "inviteUser",
  MERCHANT = "merchant",
  USER_LIST = "userList",
  ENABLE_USER = "enableUser",
  DISABLE_USER = "disableUser",
  SUBMIT_REFUND_FORM_DATA = "submitRefundForm",
  GET_REFUND_FORM = "getRefundForm",
  REFUND_EXCHANGE_RATES = "refundExchangeRates",
  RECENT_PAYMENT = "recentPayment",
  ATM_WITHDRAW = "atmWithDraw"
}

export const useApi: () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in EndpointKey]: (...args: any[]) => string
} = () => {
  return {
    getUser: (query: string) => `user?${query}`,
    sendPhoneOTP: () => "user/registration/phone/",
    verifyPhoneOTP: () => "user/registration/phone/verify/",
    userDetailsAndEmailOTP: () => "user/registration/details/",
    verifyEmailOTPAndCreateUser: () => "user/registration/verify-and-create/",
    userBalances: () => "merchantDashboard/balances",
    merchantTransaction: (id: string) => `merchant/transactions/${id}`,
    merchantTransactions: (query: string) =>
      `merchant/transaction/search?${query}`,
    refundTransactions: (query: string) =>
      `merchant/refund/transactions?${query}`,
    refundAcceptRejectTransactions: () => "merchant/refund/approve",
    refundInitiateWebLink: () => "merchantDashboard/initiateWebLinkRefund",
    refundSubmitFormWithAuth: () => "merchantDashboard/submitRefundForm",
    exchangeRates: (fiat: string) => `v1/config/exchangeRates?from=${fiat}`,
    userTransaction: (id: string) => `user/transactions/${id}`,
    userTransactions: (query: string) =>
      `merchantDashboard/transactions?${query}`,
    merchant: () => "merchantDashboard/admin/merchant/",
    p2pTransaction: () => "merchantDashboard/admin/p2p_transaction",
    generateAPIKey: () => "merchantDashboard/admin/api-key",
    inviteUser: () => "merchantDashboard/admin/invite/",
    merchantDetails: () => "merchantDashboard/merchantDetails",
    userList: () => "merchantDashboard/getAllUsersOfMerchant",
    enableUser: () => "merchantDashboard/admin/enable",
    disableUser: () => "merchantDashboard/admin/disable",
    submitRefundForm: () => "merchantDashboard/submitRefundForm",
    getRefundForm: () => "api/v1/merchantDashboard/getRefundDetailsFromToken",
    refundExchangeRates: (query: string) =>
      `api/v1/merchantDashboard/exchangeRates?from=${query}`,
    recentPayment: () => "merchantDashboard/requestPayment",
    atmWithDraw: () => "bdo/withdraw/wallet"
  } as const
}

export const calculateTimeLeft: (targetDate: Date) => number = (targetDate) => {
  const now = new Date()
  const difference = +targetDate - +now
  return difference > 0 ? Math.floor((difference / 1000) % 60) : 0
}

export const AssetFractionDigits: TokenToAmount = {
  BTC: 8,
  ETH: 8,
  USDT: 8,
  WTK: 8
}

export const FiatAssetFractionDigits: FiatTokenToAmount = {
  USD: 2,
  EUR: 2,
  INR: 1,
  IDR: 1,
  SGD: 2,
  GBP: 2,
  PKR: 1,
  PHP: 1,
  AED: 2,
  WTK: 1
}
