export const LOCAL_ENV_PUBLIC_API_URL = "http://localhost:8080/"

export enum ENV {
  DEV = "dev",
  POC = "poc",
  TESTING = "testing",
  UAT = "uat",
  PREPROD = "preprod",
  PROD = "prod"
}

type CongnitoConfig = {
  aws_project_region: string
  aws_cognito_region: string
  aws_user_pools_id: string
  aws_user_pools_web_client_id: string
  oauth: { [key in string]: string }
}

type ENV_VALUES = {
  TYPE: ENV
  PUBLIC_API_URL: string
  COGNITO_CONFIG: CongnitoConfig
  DEFAULT_COUNTRY: string
  DEFAULT_USER_EMAIL?: string
  DEFAULT_USER_PASSWORD?: string
  DEFAULT_USER_PHONE_NUMBER?: string
}

const congnitoConfig: { [key in ENV]: CongnitoConfig } = {
  dev: {
    aws_project_region: "eu-central-1",
    aws_cognito_region: "eu-central-1",
    aws_user_pools_id: "eu-central-1_YHGC0AdSw",
    aws_user_pools_web_client_id: "2pge6nhui8edjckr5fts5o0a1u",
    oauth: {}
  },
  uat: {
    aws_project_region: "ap-southeast-1",
    aws_cognito_region: "ap-southeast-1",
    aws_user_pools_id: "ap-southeast-1_EE1zE8GMT",
    aws_user_pools_web_client_id: "2f7amn9bg5877rkvou8c0b24m0",
    oauth: {}
  },
  poc: {
    aws_project_region: "ap-southeast-1",
    aws_cognito_region: "ap-southeast-1",
    aws_user_pools_id: "ap-southeast-1_9QyRkkZlp",
    aws_user_pools_web_client_id: "3icfcrqubcueiv2t1i8h56sb11",
    oauth: {}
  },
  testing: {
    aws_project_region: "ap-southeast-1",
    aws_cognito_region: "ap-southeast-1",
    aws_user_pools_id: "ap-southeast-1_jityQJ9ye",
    aws_user_pools_web_client_id: "qcakkm5vn7tuqo5umda4otuf3",
    oauth: {}
  },
  preprod: {
    aws_project_region: "ap-southeast-1",
    aws_cognito_region: "ap-southeast-1",
    aws_user_pools_id: "ap-southeast-1_qnF8ucjP1",
    aws_user_pools_web_client_id: "3sk23u48ub5ctr35u47m2o4dos",
    oauth: {}
  },
  prod: {
    aws_project_region: "ap-southeast-1",
    aws_cognito_region: "ap-southeast-1",
    aws_user_pools_id: "ap-southeast-1_qnF8ucjP1",
    aws_user_pools_web_client_id: "3sk23u48ub5ctr35u47m2o4dos",
    oauth: {}
  }
}

const envsMap: { [key in ENV]: ENV_VALUES } = {
  dev: {
    TYPE: ENV.DEV,
    PUBLIC_API_URL: "https://api.dev.wadzpay.com/",
    COGNITO_CONFIG: congnitoConfig.dev,
    DEFAULT_COUNTRY: "SK"
  },
  poc: {
    TYPE: ENV.POC,
    PUBLIC_API_URL: "https://api.poc.wadzpay.com/",
    COGNITO_CONFIG: congnitoConfig.poc,
    DEFAULT_COUNTRY: "IN"
  },
  testing: {
    TYPE: ENV.TESTING,
    PUBLIC_API_URL: "https://api.test.wadzpay.com/",
    COGNITO_CONFIG: congnitoConfig.testing,
    DEFAULT_COUNTRY: "IN"
  },
  uat: {
    TYPE: ENV.UAT,
    PUBLIC_API_URL: "https://api.uat.wadzpay.com/",
    COGNITO_CONFIG: congnitoConfig.uat,
    DEFAULT_COUNTRY: "IN"
  },
  preprod: {
    TYPE: ENV.PREPROD,
    PUBLIC_API_URL: "https://api.wadzpay.com/",
    COGNITO_CONFIG: congnitoConfig.preprod,
    DEFAULT_COUNTRY: "IN"
  },
  prod: {
    TYPE: ENV.PROD,
    PUBLIC_API_URL: "https://api.wadzpay.com/",
    COGNITO_CONFIG: congnitoConfig.prod,
    DEFAULT_COUNTRY: "IN"
  }
}

const getEnvVars = (env: string = ENV.DEV) => {
  if (!env) return envsMap[ENV.DEV]
  if (env.indexOf(ENV.DEV) !== -1) return envsMap[ENV.DEV]
  if (env.indexOf(ENV.TESTING) !== -1) return envsMap[ENV.TESTING]
  if (env.indexOf(ENV.PROD) !== -1) return envsMap[ENV.PROD]
  if (env.indexOf(ENV.POC) !== -1) return envsMap[ENV.POC]
  if (env.indexOf(ENV.PREPROD) !== -1) return envsMap[ENV.PREPROD]
  return envsMap[ENV.DEV]
}

//TODO:- Check during push
const env = getEnvVars(ENV.PROD)

export default env
